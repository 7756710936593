import React, {useRef,useState} from 'react';
import {Row,Col,Collapse, Button, Modal} from 'antd'
import ReactToPrint from 'react-to-print';
import {Link} from 'react-router-dom'




const ListItem = (props)=>{
    const componentRef=useRef(null);
    const {Panel} = Collapse;
   const {cerchi, onRemove, battistrada} = props
   const [isModalVisible,setIsModalVisible] =useState(false)
    const battistradaMap= Object.values(battistrada)
    console.log('[map-battistrda]', battistradaMap)
   const showModal= ( )=>{
    setIsModalVisible(true)
}


const handleAnnulla = ()=>{
    setIsModalVisible(false)
}

    
 
    
    return(
        
        <Row className='list-item'>
            <Col span={24}>
               
                <Collapse accordion>
          
                <Panel header={ `Codice Deposito ${props.numero} `} extra={ <Button type='default' onClick={showModal}> Cancella</Button>    } key="1">
                <div ref={componentRef}>
                    <Row>
                        <Col span={12} className='list-item-col-sx'>

                        <Modal title="Attenzione" visible={isModalVisible}  onCancel={handleAnnulla}
                          footer={[
                            <Button key="back" onClick={handleAnnulla} >
                            Annulla
                            </Button>,
                            <Button key="submit" type="primary" onClick={()=>onRemove(props.id)}  >
                            Procedi                                                   
                            </Button>
                             ]}>
                          <p>Stai per cancellare questi dati dal databse</p>
                          <p> Vuoi procedere?</p>
       
                    </Modal>
                            
                            <h3 style={{padding:'16px 0 0 8px'}}>Anagrafica cliente:</h3>
                            <ul style={{padding:'0px 0 0 8px'}}>
                                <li><b>Codice Deposito:</b> {props.numero}</li>
                                <li><b>Data deposito:</b>{props.data}</li>
                                <li><b>Nome:</b> {props.nome}</li>
                                <li><b>Cognome:</b> {props.cognome}</li>
                                <li><b>Nome Azienda:</b> {props.nomeAzienda}</li>
                                <li><b>Telefono:</b> {props.telefono}</li>
                                <li><b>Email:</b> {props.mail}</li>
                                <li><b>Modello Auto :</b>{props.modello}</li>
                                <li><b>Targa Auto:</b>{props.targa}</li>
                            </ul>
                            
                        </Col>
                        <Col span={12} className='list-item-col-dx'>
                            <h3 style={{padding:'16px 0 0 8px'}}>Lavorazione:</h3>
                            <ul style={{padding:'0px 0 0 8px'}} >
                                <li> <b>Quantità:</b> {props.quantità}</li>
                                <li> <b>Misura:</b> {props.misura}</li>
                                <li> <b>Battistrada residuo (mm):</b> 
                                    <ul>
                                    <li>Anteriore Dx : {battistradaMap[0]}</li>
                                    <li>Anteriore Sx : {battistradaMap[1]}</li>
                                    <li>Posteriore Dx : {battistradaMap[2]}</li>
                                    <li>Posteriore Sx : {battistradaMap[3]}</li>
                                    </ul>
                                </li>

                                <li><b>Marca:</b> {props.marca}</li>
                                <li><b>Cerchi:</b> {cerchi }</li>
                                
                            </ul>
                            <Link  to={'/modifica?id=' + props.id} style={{margin:'8px'}}>Modifica</Link>
                            <Link to={'/lavorazione?id=' + props.id} style={{margin:'8px'}}>Stampa</Link>
                            
                        </Col>
                    </Row>
                    </div>
                    </Panel>
                   
                </Collapse>
                
            </Col>
        </Row>
    )
}

export default ListItem;