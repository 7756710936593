import {useRef, useEffect, useState} from 'react';
import {Row,Col, Button} from 'antd'
import ReactToPrint from 'react-to-print';
import {useSearchParams} from 'react-router-dom';
import { getDatabase, ref, child, get, remove } from "firebase/database";
import car from '../asset/car.png'
import logo from '../asset/logo_trasp.png';


const ItemView = (props)=>{

    const [data,setData]=useState({});
    const [searchParams,setSearchParams]=useSearchParams()
    const[isData, setIsData] =useState(false)
    const dbRef =ref(getDatabase());
    const componentRef = useRef(null)

   useEffect(()=>{
       console.log('[data]', data, '[props]', props)
   }, [data])

    useEffect(() =>get(child(dbRef,'lavorazione/' + searchParams.get('id'))).then((snapshot)=>{
        if(snapshot.exists()){
            const snap = snapshot.val()
            setData(snap)
            setIsData(true)
            
                
        }else{
            console.log('[GET] NO data available')
           
        }
    }).catch((error)=>{
        console.log(error)
    })
        
     , [])

    return(
        <div className='view-container' ref={componentRef}>
            <div className='intestazione'>
              <Row justify='center' align='middle' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={12} style={{textAlign:'center'}}>
                  <img src={logo} style={{maxWidth:'130px', maxHeight:'130px'}}></img>

                  </Col>
                  <Col span={12}>
                      <p >Allasia gomme s.r.l <br/> Via Poirino 299/5 <br/> 10022 CArmagnola TO <br/> Tel. 011.9795203 </p>
                  </Col>
              </Row>
            </div>   
            {!isData ? <p>Caricamento in corso... </p> : 
                   
                  <div>
 <Row>
                        <span className='stampa-title'> 
                                <h1>DEPOSITO STAGIONALE</h1>
                        </span>
                      </Row>
                           
                        <Row justify='center'>
                            <Col lg={16} xs={12} sm={12} className='stampa-col'>
                              <b>Data Deposito:</b>   {data.date}
                            </Col>
                            <Col lg={6} xs={12} sm={12} className='stampa-col'>
                                <b>Numero Deposito:</b> <span style={{fontSize:'24px'}}>{data.numero}</span>
                            </Col>
                        </Row>
                        <Row justify='center'>
                            <Col lg={14} xs={12} sm={12} className='stampa-col'>
                                <b>Cliente :</b> <p>{data.nome +  ' '+  data.cognome}</p>
                                <p>{data.nomeAzienda}</p>
                            </Col>
                            <Col lg={8} xs={12} sm={12} className='stampa-col'>
                                <b>Contatti:</b>
                                <p>{data.mail}</p>
                                <p>{data.telefono}</p>
                            </Col>
                        </Row>
                        <Row justify='center'>
                            <Col lg={11} xs={12} sm={12} className='stampa-col'>
                                <b>Vettura:</b> {data.modello}
                            </Col>
                            <Col lg={11} xs={12} sm={12} className='stampa-col'>
                                <b>Targa:</b> {data.targa}
                            </Col>
                        </Row>
                        <Row justify='center'>
                            <Col lg={11} xs={12} sm={12} className='stampa-col'>
                                <b>Misura:</b> {data.misura}
                            </Col>
                            <Col lg={11} xs={12} sm={12} className='stampa-col'>
                                <b>Marca:</b> {data.marca}
                            </Col>
                        </Row>
                        <Row justify='center' className='stampa-row-img'>
                            <Col lg={11} xs={12} sm={12} className='stampa-col-img'>
                                <Row style={{padding:'8px'}}>
                                    <b>Battistrada Residuo (mm):</b>
                                </Row>
                                <Row justify='space-around' style={{padding:'8px'}}>
                                    <Col style={{display:'flex', flexFlow:'column', gap:'30px'}}>
                                        <Row style={{paddingTop:'24px'}}>
                                            {data.battistrada.aSx}
                                        </Row>
                                        <Row>
                                            {data.battistrada.pSx}
                                        </Row>
                                    </Col>
                                    <Col>
                                        <img src={car} className='stampa-img'/>
                                    </Col>
                                    
                                    <Col style={{display:'flex', flexFlow:'column', gap:'30px'}}>
                                        <Row style={{paddingTop:'24px'}}>
                                        {data.battistrada.aDx}
                                        </Row>
                                        <Row>
                                        {data.battistrada.pDx}
                                        </Row>
                                    </Col>
                                </Row>
                                
                            </Col>
                            <Col lg={11} xs={12} sm={12} style={{margin:'8px'}} >
                                <Row className='stampa-inner-row ' gutter={16} justify='space-between'>
                                    <Col span={11} style={{border:'1px solid black', height:'100px', padding:'8px' ,margin:'0 8px 8px 8px'}}>
                                     <b>Quantità:</b> {data.quantità}
                                    </Col>
                                    <Col span={11} style={{border:'1px solid black', height:'100px', padding:'8px', marginLeft:'8px'}} >
                                    <b>Cerchi:</b> {data.cerchi}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='disclaimer' style={{flexFlow:'column', marginTop:'16px'}}>
                            <h4>Regolamento</h4>
                            
                            <p>
                                Il deposito stagionale è il servizio di presa in carico e custodia degli pneumatici dei nostri clienti.<br></br>
                                Una volta smontati verranno catalogati e stoccati nel modo migliore per garantirne l'integrità.<br/>
                                Il termine massimo di giacenza senza movimentazione degli pneumatici è di 12 mesi.<br/>
                                Allasia Gomme s.r.l si impegna a contattare il cliente a seguito di assenza di movimentazione degli pneumatici per un periodo superiore ai 6 mesi.<br/> 
                                Se, entro i 12 mesi sopra citati, il cliente non avrà provveduto ad effettuare un cambio dei pneumatici o prelevato i pneumatici dal magazzino 
                                , questi ultimi verranno rimossi dal magazzino e successivamente smaltiti.</p>
                            
                                
                          
                        </Row>
                        <Row style={{marginTop:'40px'}}>
                            Firma : ___________________________________________
                        </Row>
                  </div>
                     
                }        

                    <ReactToPrint
                                        trigger={()=> <Button type='primary' className='no-print' style={{margin:'8px'}}>Stampa</Button>}
                                        content={()=> componentRef.current}
                                    /> 

              
                
            

        </div>
    )
}

export default ItemView