import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Alert,
  Radio,
  Button,
  Form,
  Space,
  DatePicker
  
} from "antd";
import {useSearchParams} from 'react-router-dom';
import { getDatabase, ref, get, child, update} from "firebase/database";
import {useNavigate} from 'react-router-dom';

const UpdateItem = () => {
  const [data, setData]=useState({
    numero: 0,
    date: '' ,
    nome: "",
    battistrada:{
      aDx:'',
      aSx:'',
      pDx:'',
      pSx:'',
    },
    cognome: "",
    nomeAzienda: "",
    modello: "",
    targa: "",
    telefono: "",
    mail: "",
    quantità: '',
    misura: "",
    marca: "",
    cerchi: "",
  })
  const [searchParams,setSearchParams]=useSearchParams()
  const [success, setSuccess] = useState(false);
  const dbRef =ref(getDatabase());
  const updated ={};
  updated['lavorazione/' + searchParams.get('id')] = data;
  const dateFormat = "DD/MM/YYYY";
  const navigate =useNavigate();


  //get current data
  useEffect(() =>get(child(dbRef,'lavorazione/' + searchParams.get('id'))).then((snapshot)=>{
    if(snapshot.exists()){
        setData(snapshot.val())
        console.log('[CONVERTED-DATA]',snapshot.val(), '[stato]', data);
    }else{
        console.log('[GET] NO data available')
    }
}).catch((error)=>{
    console.log(error)
})
 , [])

//update data
 const updateHandler=()=>{
  update(dbRef, updated)
  setSuccess(true);
  console.log("[PRE-TIMEOUT", success);
  setTimeout(() => {
    setSuccess(false);
    navigate('/')
    console.log("[TIMEOUT]", success);
  }, 2000);
  
}

//handlers
  useEffect(() => {
    console.log("[UPDATE-DATA]", data);
  }, [data]);

  const onChangeDate = (date, dateString) => {

    setData({ ...data, date: dateString});
  }; 

  const onChangeBattistrada=(e)=>{
    e.preventDefault()
    const value = e.target.value
    let battistradaRes= {...data}
    battistradaRes.battistrada[e.target.name] = value
    setData(battistradaRes)
     
    }

  //2WAY BINDING FORM
  function onChange(e) {
    const value = e.target.value ;
    setData({
      ...data,
      [e.target.name]: value
    });

    console.log("[CHANGE-VALUE]", value);
    console.log("[event]", e);
  }
  const [form] = Form.useForm();
  const clear = () => {
    form.resetFields();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={updateHandler}
      autoComplete="off"
    >
      <Row>
        <Col lg={12} xs={24} sm={24} className="form-container">
          <h2>CODICE LAVORAZIONE</h2>
         <Space direction="vertical" size='large'>
         <span style={{fontSize:'32px'}}>{data.numero}</span>
         <DatePicker
              onChange={onChangeDate}
              format={dateFormat}
              placeholder={data.date}
              name="date"
             
       
            />
          </Space>
          <h2>ANAGRAFICA CLIENTE</h2>
          <Form.Item
            name="nome"
            label="Nome"
            rules={[ { type: "string", min: 3 }]}
            initialValue={data.nome}

          >
            <Input
              placeholder={data.nome}
              value={data.nome}
              name="nome"
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            name="cognome"
            label="Cognome"
            rules={[ { type: "string", min: 3 }]}
            initialValue={data.cognome}
          >
            <Input
              placeholder={data.cognome}
              value={data.cognome}
              name="cognome"
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            name="nomeAzienda"
            label="Nome Azienda"
            rules={[ { type: "string", min: 3 }]}
            initialValue={data.nomeAzienda}
           
          >
            <Input
              placeholder={data.nomeAzienda}
              
              value={data.nomeAzienda } 
              name="nomeAzienda"
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            name="modello"
            label="Modello"
            rules={[ { type: "string", min: 3 }]}
            initialValue={data.modello}
          >
            <Input
              placeholder={data.modello}
              value={data.modello}
              name="modello"
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            name="targa"
            label="Targa"
            rules={[ { type: "string", min: 3 }]}
            initialValue={data.targa}
          >
            <Input
              placeholder={data.targa}
              value={data.targa}
              name="targa"
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            name="telefono"
            label="Numero di Telefono"
            rules={[ {  min: 10 }]}
            initialValue={data.telefono}
          >
            <Input
              placeholder={data.telefono}
              type='number'
              value={data.telefono}
              name="telefono"
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            

            rules={[
              { required: false },
              { type: "email", warningOnly: true },
              { type: "string",  },
            ]}
            initialValue={data.mail}
          >
            <Input
              placeholder={data.mail}
            
              value={data.mail }
              name="mail"
              onChange={onChange}
            />
          </Form.Item>

          <h2>SERVIZIO</h2>
          <Form.Item
            name="quantità"
            label="Quantità"
            initialValue={data.quantità}
          >
            <Input
              type="number"
              placeholder={data.quantità}
              value={data.quantità}
              name="quantità"
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            name="misura"
            label="Misura"
            rules={[ { type: "string", min: 2 }]}
            initialValue={data.misura}
          >
            <Input
            placeholder={data.misura}
              value={data.misura}
              name="misura"
              onChange={onChange}
            />
          </Form.Item>
          
          <Form.Item
            name="marca"
            label="Marca"
            rules={[{ type: "string", min: 3 }]}
            initialValue={data.marca}
          >
            <Input
              placeholder={data.marca}
              value={data.marca}
              name="marca"
              onChange={onChange}
            />
          </Form.Item>
          
          <h4>Battistrada Residuo (mm)</h4>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={12}>
                    <Form.Item
                      name="aDx"
                      label="Anteriore Dx"
                      initialValue={data.battistrada.aDx}
                    >
                      <Input
                        placeholder={data.battistrada.aDx}
                        type='number'
                        value={data.battistrada.aDx }
                        name="aDx"
                        onChange={onChangeBattistrada}
                      />
                    </Form.Item>
                
                  </Col>
                  <Col span={12}>
                  <Form.Item
                      name="aSx"
                      label="Anteriore Sx"
                      initialValue={data.battistrada.aSx}
                    >
                      <Input
                        placeholder={data.battistrada.aSx}
                        type='number'
                        value={data.battistrada.aSx }
                        name="aSx"
                        onChange={onChangeBattistrada}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={12}>
                    <Form.Item
                      name="pDx"
                      label="Posteriore Dx"
                      initialValue={data.battistrada.pDx}
                    >
                      <Input
                        placeholder={data.battistrada.pDx}
                        type='number'
                        value={data.battistrada.pDx }
                        name="pDx"
                        onChange={onChangeBattistrada}
                      />
                    </Form.Item>
                
                  </Col>
                  <Col span={12}>
                  <Form.Item
                      name="pSx"
                      label="Posteriore Sx"
                      initialValue={data.battistrada.pSx}
                    >
                      <Input
                        placeholder={data.battistrada.pSx}
                        type='number'
                        value={data.battistrada.pSx }
                        name="pSx"
                        onChange={onChangeBattistrada}
                      />
                    </Form.Item>
                  </Col>
                </Row>
          <p>Cerchi</p>
          <Space direction='vertical' size='large'>
            
            <Radio.Group
              value={data.cerchi}
              name="cerchi"
              onChange={onChange}
              defaultValue={data.cerchi}
             
            >
              <Radio value={"si"}>Si</Radio>
              <Radio value={"no"}>No</Radio>
            </Radio.Group>
        
          <Form.Item>
            
              <Button type="primary" htmlType="submit">
                Aggiorna
              </Button>
              {success && (
                <Alert
                  message="la lavorazione è stata modificata con successo"
                  type="success"
                  style={{ marginTop: "16px" }}
                />
              )}
            
          </Form.Item>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default UpdateItem;
